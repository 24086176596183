import * as React from 'react';

const ThankYouHeroBG = ({ children }) => {
  return (
    <div className="relative h-[50vh] overflow-hidden">
      <div className="bg absolute h-[50vh]" />
      <div className="bg bg2 absolute h-[50vh]" />
      <div className="bg bg3 absolute h-[50vh]" />
      <div className="content flex h-full items-center justify-center">{children}</div>
    </div>
  );
};

export default ThankYouHeroBG;
